<template>
  <div>

    <div class="jumbotron jumbotron-fluid title__secondary">
      <div class="container"><h1 class="display-4 title-center">Privacy en voorwaarden</h1>
        <p class="lead--centered"></p></div>
    </div>

    <div class="bg--white">
      <div class="container max-width--1000px">

        <div class="space--50"></div>

        <div class="row">
          <div class="col" style="text-align:center">
            <img src="/img/privacy.svg" alt="" style="width:50px;padding-bottom:5px">
            <h1 class="display-6 title-center--black">Privacy</h1>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <br>
            <p>
              We vinden je privacy ontzettend belangrijk en hebben daarom op deze pagina beschreven hoe we
              met de gegevens
              die we van je krijgen om gaan.
            </p>
            <p>
              Je kunt het beste onze hele beschrijving lezen, maar het komt op het volgende neer. We
              verwerken maar een paar
              persoonsgegevens van je, zoals je IP-adres en informatie over je browser. Die gegevens slaan
              we voor maximaal 3 maanden op.
              Dat doen we om misbruik op onze diensten te voorkomen. Om te controleren of de link die je
              checkt veilig is versturen we deze
              naar Google Safe Browsing. Dat doen we zonder enige persoonsgegevens van je mee te sturen.
              We geven dus niet je IP-adres of browserinformatie
              door aan Google.
            </p>
            <p>
              We kunnen de link die je checkt delen met onze partners als we vermoeden dan het om een
              phishing site gaat. We delen
              de link dan slechts met de partner waarvan we denken dat hij gephist wordt. We zullen
              <i>nooit</i>
              persoonlijke informatie
              meesturen. We kunnen dus wel een link delen, maar we delen niet <i>wie</i> die link bij ons
              heeft gecheckt.
            </p>
              <!-- @TODO: Re-add after launch -->
<!--            <p>-->
<!--              Wanneer je een betaalverzoek bij ons checkt proberen we je bij een aantal aanbieders de begunstigde van-->
<!--              het verzoek te laten zien. Deze gegevens vragen we rechtstreeks op bij de aanbieder. De gegevens van een-->
<!--              betaalverzoek (zoals de tenaamstelling) slaan we niet op. Zelfs niet voor even.-->
<!--            </p>-->
            <p>
              We zijn blij met je vertrouwen in onze dienst, en zullen er alles aan doen om dat nooit te
              schaden. We zullen je gegevens nooit verkopen en ons blijven
              inspannen om ze zo goed mogelijk te beschermen.
            </p>
            <br>

            <router-link to="/privacy" class="btn btn-outline-primary">Lees onze privacyverklaring</router-link>

          </div>
        </div>
      </div>

      <br><br>

      <div class="container max-width--1000px">

        <div class="row">
          <div class="col" style="text-align:center">
            <img src="/img/accepted.svg" alt="" style="width:50px;padding-bottom:5px">
            <h1 class="display-6 title-center--black">Voorwaarden</h1>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <br>
            <h3>Disclaimer voor checkjelinkje.nl</h3>
            <p>
              Checkjelinkje B.V. (Kamer van Koophandel: 82862222), hierna te noemen Checkjelinkje, verleent u
              hierbij
              toegang tot checkjelinkje.nl ("de Website") en nodigt u uit de hier aangeboden diensten af
              te
              nemen.
              Checkjelinkje behoudt zich daarbij het recht voor op elk moment de inhoud aan te passen of
              onderdelen
              te verwijderen zonder daarover aan u mededeling te hoeven doen.
            </p>
            <br>
            <h3>Beperkte aansprakelijkheid</h3>
            <p>
              Checkjelinkje spant zich in om de inhoud van de Website zo vaak mogelijk te actualiseren en/of aan
              te
              vullen. Ondanks deze zorg en aandacht is het mogelijk dat inhoud onvolledig en/of onjuist
              is.
              De op de Website aangeboden materialen worden aangeboden zonder enige vorm van garantie of
              aanspraak
              op juistheid. Deze materialen kunnen op elk moment wijzigen zonder voorafgaande mededeling
              van
              Checkjelinkje.
              In het bijzonder zijn alle prijzen op de Website onder voorbehoud van type- en
              programmeerfouten.
              Voor de gevolgen van dergelijke fouten wordt geen aansprakelijkheid aanvaard. Geen
              overeenkomst
              komt
              tot stand op basis van dergelijke fouten. Gebruikers kunnen zelf inhoud plaatsen op de
              Website.
              Checkjelinkje oefent hierop geen voorafgaande controle of redactioneel toezicht uit, maar zal
              klachten
              over
              gebruikersinhoud serieus onderzoeken en waar nodig ingrijpen. Neem hiervoor contact met ons
              op
              via
              het contactformulier.Voor op de Website opgenomen hyperlinks naar websites of diensten van
              derden
              kan Checkjelinkje nimmer aansprakelijkheid aanvaarden.
            </p>
            <br>
            <h3>Auteursrechten</h3>
            <p>
              Alle rechten van intellectuele eigendom betreffende deze materialen liggen bij Checkjelinkje en
              haar
              bezoekers.
              Kopiëren, verspreiden en elk ander gebruik van deze materialen is niet toegestaan zonder
              schriftelijke toestemming van Checkjelinkje, behoudens en slechts voor zover anders bepaald in
              regelingen
              van dwingend recht (zoals citaatrecht), tenzij bij specifieke materialen anders aangegeven
              is.
            </p>
            <br>
            <h3>Overig</h3>
            <p>
              Deze disclaimer kan van tijd tot tijd wijzigen.
            </p>
            <br>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Juridisch',
};
</script>
